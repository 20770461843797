import React, { useRef, useEffect, useCallback } from 'react';
import { userSpring, animated } from 'react-spring';

import {
  Container,
  Background,
  ModalWrapper,
  Button,
  FormButton,
  ModalImg,
  ModalContent,
  CloseModalButton,
} from './styledModalComponents';
import Modal from 'react-modal';
import ContactForm from '../../contact/index';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'transparent',
  },
};

Modal.setAppElement(document.getElementById('root'));

const SendEmailModal = ({ show, onClose }) => {
  return (
    <>
      {show ? (
        <Modal
          isOpen={show}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={onClose}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ModalWrapper>
            <ModalImg src={require('../../images/svg-10.svg')} />
            <ModalContent>
              <ContactForm />
            </ModalContent>
            <CloseModalButton aria-label="Close modal" onClick={onClose} />
          </ModalWrapper>
        </Modal>
      ) : // <Background>
      //   <ModalWrapper>
      //     <ModalImg src={require('../../images/svg-10.svg')} />
      //     <ModalContent>
      //       <h1>modal content</h1>
      //       <p>write text here..</p>
      //     </ModalContent>
      //     <CloseModalButton aria-label="Close modal" onClick={onClose} />
      //   </ModalWrapper>
      // </Background>
      null}
    </>
  );
};

export default SendEmailModal;
