import React from 'react';
import { Button } from '../ButtonElements';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  SocialIconLink,
  SocialIcons,
  PictureWrap,
  Picture,
  ServicesSubtitle,
  ServiceImgWrap,
} from './InfoElements';

const InfoSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2,
  contact,
  picture,
  buttonDestination,
  services,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  showSendEmailModal,
  closeSendEmailModal,
}) => {
  console.log(primary);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            {picture ? (
              <Column1>
                <TextWrapper>
                  <TopLine>{topLine}</TopLine>

                  <PictureWrap>
                    <Picture src={picture} alt={alt} />
                  </PictureWrap>

                  {/* <Heading lightText={lightText}>{headline}</Heading> */}
                  <Subtitle darkText={darkText}>{description}</Subtitle>

                  <BtnWrap>
                    <Button
                      to={buttonDestination}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                    >
                      {buttonLabel}
                    </Button>
                  </BtnWrap>
                </TextWrapper>
              </Column1>
            ) : (
              <Column1>
                <TextWrapper>
                  <TopLine>{topLine}</TopLine>
                  {contact ? (
                    <SocialIcons>
                      <SocialIconLink
                        href="https://www.facebook.com/johanna.salkvist"
                        target="_blank"
                        aria-label="Facebook"
                      >
                        <FaFacebook />
                      </SocialIconLink>
                      <SocialIconLink
                        href="https://www.instagram.com/salkvist"
                        target="_blank"
                        aria-label="Instagram"
                      >
                        <FaInstagram />
                      </SocialIconLink>

                      <SocialIconLink
                        href="https://www.linkedin.com/in/johanna-salkvist-36105814b"
                        target="_blank"
                        aria-label="Linkedin"
                      >
                        <FaLinkedin />
                      </SocialIconLink>
                      <SocialIconLink
                        // href="/"
                        target="_blank"
                        aria-label="Mail"
                        onClick={showSendEmailModal}
                      >
                        <GrMail />
                      </SocialIconLink>
                    </SocialIcons>
                  ) : services ? (
                    <>
                      {' '}
                      <Heading lightText={lightText}>{headline}</Heading>
                      <ServicesSubtitle darkText={darkText}>
                        {description}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description1}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description2}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description3}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description4}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description5}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description6}
                      </ServicesSubtitle>
                      <ServicesSubtitle darkText={darkText}>
                        {description7}
                      </ServicesSubtitle>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Heading lightText={lightText}>{headline}</Heading>
                      <Subtitle darkText={darkText}>{description}</Subtitle>
                    </>
                  )}

                  {contact ? null : (
                    <BtnWrap>
                      <Button
                        to={buttonDestination}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </Button>
                    </BtnWrap>
                  )}
                </TextWrapper>
              </Column1>
            )}

            <Column2>
              {services ? (
                <ServiceImgWrap>
                  <Img src={img} alt={alt} />
                </ServiceImgWrap>
              ) : (
                <ImgWrap>
                  <Img src={img} alt={alt} />
                </ImgWrap>
              )}
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
