import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalWrapper = styled.div`
  width: 800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;

  @media screen and (max-width: 680px) {
    margin-top: 60px;
    /* width: 320px; */
    width: 100%;
    height: 80vh;
    flex-direction: column;
  }
`;

const Button = styled.button`
  min-width: 100px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  background: #141414;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

const ModalImg = styled.img`
  width: 60%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;

  @media screen and (max-width: 680px) {
    width: 100%;
    height: 25%;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }

  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;

  @media screen and (max-width: 680px) {
    color: #ffff;
  }
`;

export {
  Container,
  Background,
  ModalWrapper,
  Button,
  FormButton,
  ModalImg,
  ModalContent,
  CloseModalButton,
};
