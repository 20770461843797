import React, { useState } from 'react';
import { Axios, db } from '../firebase/firebaseConfig';
import toast, { Toaster } from 'react-hot-toast';
import {
  collection,
  setDoc,
  updateDoc,
  doc,
  Timestamp,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
// import './styled.scss';

import {
  StyledTextarea,
  StyledButton,
  StyledInput,
} from './styledFormComponents';

const ContactForm = () => {
  const [formData, setFormData] = useState({});

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };
  const sendEmail = () => {
    const emailCollectionRef = collection(db, 'emails');
    const emailDocumentRef = doc(emailCollectionRef);
    const emailToAdd = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      time: new Date(),
    };

    Axios.post(
      'https://us-central1-johannas-website.cloudfunctions.net/submit',
      formData
    )
      .then((res) => {
        setDoc(emailDocumentRef, emailToAdd);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sucessNotify = () => toast.success('Email sent!');

  return (
    <>
      <Toaster
        toastOptions={{
          duration: 3000,
          className: '',
          style: {
            padding: '16px',
            color: '#141414',
            background: 'white',
            marginTop: 100,
          },
        }}
      />
      <form
        style={{
          flex: 'display',
          alignContent: 'center',
          alignItems: 'center',
          display: 'table',
          margin: '40px auto',
        }}
        onSubmit={handleSubmit}
      >
        <StyledInput
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.name || ''}
        />
        <StyledInput
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateInput}
          value={formData.email || ''}
        />
        <StyledTextarea
          type="text"
          name="message"
          placeholder="Message"
          onChange={updateInput}
          value={formData.message || ''}
        ></StyledTextarea>
        <StyledButton
          type="submit"
          onClick={() => {
            sucessNotify();
          }}
        >
          Send email
        </StyledButton>
      </form>
    </>
  );
};

export default ContactForm;
