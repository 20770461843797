export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Johanna salkvist',
  headline: 'Unlimited Transactions with zero fees',
  // description: `My name is Johanna and I am the founder of AttractiveConsulting. The company was founded because of my great passion for the beauty industry. Me and my team have learned digital marketing from the absolute foremost experts in this area in a successful marketing school called "giring academy", and we have years of experience.`,
  description: `My name is Johanna Salkvist and I am the founder of this company. The company was founded because of my great passion for marketing. I am proud to say that I have learned digital marketing from the absolute foremost experts in this area in a marketing school called “Giring Academy”.`,

  buttonLabel: 'Services',
  imgStart: false,
  img: require('../../images/svg-8.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false,
  picture: require('../../images/johanna-1.jpeg'),
  buttonDestination: 'services',
};

export const homeObjTwo = {
  id: 'contact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'CONTACT US',
  headline: '',
  description: '',
  buttonLabel: 'Send email',
  imgStart: true,
  img: require('../../images/svg-7.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true,
  contact: true,
};

export const homeObjThree = {
  id: 'services',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our Services',
  headline: '',
  description:
    'Do you have your own company and experience challenges in finding customers who want to buy your products or services?',
  description1:
    'Well, then you have come to the right place. My marketing agency helps companies all over the world to increase their sales through paid marketing on social media. I mainly use Facebook and Instagram, which today are the largest channels. ',

  description2:
    'I take care of everything for you and deliver you a continuous stream of new customers who are interested in buying your services, which saves you a lot of time and gives you a greater profit.',
  description3:
    'I am truly passionate about what I do and I know that I can deliver you great results!',
  description4: 'Of course i always offer you a guarantee!',
  description5: 'What do you have to lose?',
  description6:
    'Click the button below, and book a completely free consulting call with us.',
  description7: 'I really look forward to hearing from you!',
  // description1:
  //   'Do you have a desire to grow your business to a higher level, but do not know how?',
  // description2:
  //   'Then you have come to the right place! AttractiveConsulting helps companies all over the world to increase their sales through paid marketing on social media. We mainly use Facebook and Instagram, which today are the largest channels.',
  // description3:
  //   'AttractiveConsulting takes care of everything for you and delivers you a continuous stream of new customers who are interested in buying your services, which saves you a lot of time and help you to increase your company´s revenue.',
  // description4:
  //   'We are passionate about what we do and we know that we can deliver you great results, and of course we always offer you a guarantee!',
  // description5:
  //   "Let's kickstart 2022 together! Click the button below, and book a completely free consulting call with us.",
  // description6: 'Looking forward to hearing from you!',

  buttonLabel: 'Contact',
  imgStart: true,
  img: require('../../images/svg-9.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true,
  buttonDestination: 'contact',
  services: true,
};
