import styled from 'styled-components';

const StyledFormH1 = styled.h1`
  display: table;
  margin: 40px auto;
  color: #fff;
  font: 30px Helvetica;
  letter-spacing: 2px;
  text-transform: uppercase;
  letter-spacing: 10px;
`;
const StyledTextarea = styled.textarea`
  font: 18px Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 20px;
  width: 300px;
  margin-bottom: 20px;
  font-size: 18px;
  outline: none;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    transition: all 0.2s ease-in-out;
    color: #999;
    font: 18px Helvetica, Arial, sans-serif;
  }

  &:focus,
  &.populated {
    padding-top: 28px;
    padding-bottom: 12px;

    &::placeholder {
      color: transparent;
    }
  }
`;
const StyledInput = styled.input`
  font: 14px Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 20px;
  width: 300px;
  margin-bottom: 20px;
  font-size: 18px;
  outline: none;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    transition: all 0.2s ease-in-out;
    color: #999;
    font: 18px Helvetica, Arial, sans-serif;
  }

  &:focus,
  &.populated {
    padding-top: 28px;
    padding-bottom: 12px;

    &::placeholder {
      color: transparent;
    }
  }
`;
const StyledButton = styled.button`
  /* display: flex; */
  transition: all 0.2s ease-in-out;
  font: 18px Helvetica, Arial, sans-serif;
  border: none;
  border-radius: 5px;
  background: #1792e8;
  color: #fff;
  padding: 20px;
  width: 80%;
  /* text-transform: uppercase; */
  letter-spacing: 5px;
  align-self: flex-end;
  justify-self: flex-start;
  margin-left: 40px;
  /* margin-top: 80px; */
  &:hover {
    background: #01bf71;
    cursor: pointer;
  }

  @media screen and (max-width: 680px) {
    margin-left: 30px;
  }
`;

export { StyledFormH1, StyledTextarea, StyledButton, StyledInput };
